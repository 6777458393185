import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import FlagIcon from '@mui/icons-material/Flag';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const theme = createTheme();
export default function InfoProf(props){
    return(
        <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} sx={{mx: '2vw',my:'1vh',borderRadius:2}} elevation={6}>
        <Button
                type='button' size='large'
                startIcon={<KeyboardBackspaceIcon/>}
                sx={{ mt: 3,mx:'2vw',color:'white' }}
                onClick={() => props.setIsProfessorInfoVisible(false)}
              >
                Înapoi
              </Button>
            <Box component={Paper} elevation={6}
            sx={{
              my:'1vh',
              mb:'2vh',
              justifyContent:'space-around',
              borderRadius:2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh',mb:'2vh', bgcolor: 'secondary.main' }}>
              <FlagIcon />
            </Avatar>
            <Typography sx={{mb:'4vh'}} align='center' component='h1' variant='h4'>
            Inițializare
            </Typography>
            <Box sx={{width:'100%',px:'4vw'}}>
            <Typography sx={{mb:'0.5vh'}} align='left' fontWeight='bold' component='h5' variant='h5'>
            1. Accesarea contului 
            </Typography>
            <Typography sx={{mb:'6vh'}} align='left' component='h5' variant='h5'>
            Autentificarea în cadrul acestei platformei de asociere pentru licență se realizează prin intermediul
            contului dvs. instituțional oferit de către A.S.E. Platforma este destinată atât studenților
            care doresc să se înscrie sesiunea de licență a anului universitar curent, cât și profesorilor
            coordonatori.
            </Typography>
            <Typography sx={{mb:'0.5vh'}} align='left' fontWeight='bold' component='h5' variant='h5'>
            2. Completarea datelor personale
            </Typography>
            <Typography sx={{mb:'6vh'}} align='left' component='h5' variant='h5'>
            Completați datele personale în pagina de profil, din colțul dreapta sus, dând click pe iconița de meniu.
            Este necesară completarea specializării pentru ca studenții să vă regăsească în tabelul de profesori disponibili 
            și să vă poată solicita colaborarea. 
            </Typography></Box>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my:'1vh',
              mb:'2vh',
              justifyContent:'space-around',
              borderRadius:2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh',mb:'2vh', bgcolor: 'secondary.main' }}>
              <AppSettingsAltIcon />
            </Avatar>
            <Typography sx={{mb:'4vh'}} align='center' component='h1' variant='h4'>
            Fluxul aplicației
            </Typography>
            <Box sx={{width:'100%',px:'4vw'}}>
            <Typography sx={{mb:'0.5vh'}} align='left' fontWeight='bold' component='h5' variant='h5'>
            1. Colaborări în așteptare
            </Typography>
            <Typography align='left' component='h5' variant='h5'>
            Aici se regăsește numărul curent de locuri disponibile, afișat și grafic, pentru o mai bună evidență. 
            Vă reamintim că locurile disponibile sunt în număr de 15, cu modificări excepționale.
            Odată ce un student vă trimite o solicitare, acesta va apărea în primul tab afișat, regăsindu-se 
            detaliile sale (nume complet, an III/suplimentar, grupa), dar și titlul de licență propus și un mesaj pentru dvs.
            Îi puteți accepta sau respinge solicitarea, caz în care acesta nu vă mai poate lansa din nou una nouă. După o 
            perioadă de 48 ore, studentul poate să anuleze această solicitare, dacă s-a răzgândit sau consideră că 
            timpul de așteptare este mult prea mare.
            </Typography>
            <Typography sx={{mb:'6vh'}} color='orange' align='left' component='h5' variant='h5'>
            <i> De aceea, vă rugăm să fiți prompți în a răspunde.</i>
            </Typography>
            <Typography sx={{mb:'0.5vh'}} align='left' fontWeight='bold' component='h5' variant='h5'>
            2. Studenți acceptați
            </Typography>
            <Typography sx={{mb:'6vh'}} align='left' component='h5' variant='h5'>
            În cadrul acestei secțiuni, puteți vizualiza cu ușurință studenții dumneavoastră cu care veți colabora.
            De asemenea, le puteți descărca cererea semnată de aceștia (dacă este încarcată), pentru semnare și 
            încarcare.
            </Typography>
            <Typography sx={{mb:'0.5vh'}} align='left' fontWeight='bold' component='h5' variant='h5'>
            3. Cereri de semnat
            </Typography>
            <Typography sx={{mb:'1vh'}} align='left' component='h5' variant='h5'>
            Ultima secțiune conține doar studenții acceptați care au o cerere încărcată, pentru o mai bună organizare.
            Aici, puteți și respinge cererea acestora, din motive obiective.
            În acest caz, studentul va trebui să vă retrimită cererea modificată.
            </Typography><Typography sx={{mb:'6vh'}} align='left' component='h5' variant='h5'>
            Aici se produce și posibila modificare a titlului de licență, după ce s-a semnat deja o cerere
            în prealabil, în cazul în care una din părți dorește acest lucru.
            </Typography></Box>
          </Box>
          <Box component={Paper} elevation={6}
            sx={{
              my:'1vh',
              mb:'2vh',
              justifyContent:'space-around',
              borderRadius:2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ mt: '4vh',mb:'2vh', bgcolor: 'secondary.main' }}>
              <MarkEmailReadIcon />
            </Avatar>
            <Typography sx={{mb:'4vh'}} align='center' component='h1' variant='h4'>
            Mail
            </Typography>
            <Box sx={{width:'100%',px:'4vw'}}>
            <Typography sx={{mb:'6vh'}} align='left' component='h5' variant='h5'>
           Nu uitați să vă verificați mail-ul! Știm că fluxul de mesaje poate fi copleșitor, însă
           veți primi doar în anumite cazuri și informare prin poșta electronică.
            </Typography>
           </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
    )
}

