import React, { useState } from 'react';
import Modal from '../ui/Modal';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import { Grid, Box } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RequestModal(props) {
  const [titlu, setTitlu] = useState('');
  const [mesaj, setMesaj] = useState('');

  return (
    <Modal
      open={true}
      closed={() => props.updateRequestModal({ active: false })}
    >
      <Grid >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',  width: '65vw', height: '27rem'
          }}
        >
          <Avatar sx={{ m: 1 }}>
            <EmailIcon />
          </Avatar>
          <Typography align='center' component='p' sx={{ mb: 2 }} variant='p'>
            Introdu tematica lucrării de licență și un mesaj profesorului ales. Titlul este unul orientativ, ce <b>poate fi modificat </b> ulterior.
          </Typography>
          <TextField
            margin='normal'
            fullWidth
            id='titltulicenta2'
            placeholder='Titlu orientativ licență'
            onChange={(e) => setTitlu(e.target.value)}
            type='text'
            label='Titlu orientativ licență'
            name='Titlul licentei'
            autoComplete='Titlul licentei'
            required
            style={{
              width: '100%',
              resize: 'none',
              fontFamily: 'sans',
              fontSize: '1.2em',
            }}
          />
          <TextareaAutosize
            margin='normal'
            required
            aria-label='empty textarea'
            placeholder='Mesaj'
            minRows={10}
            onChange={(e) => setMesaj(e.target.value)}
            style={{
              width: '100%',
              resize: 'none',
              padding: '0.7em',
              fontFamily: 'sans',
              fontSize: '1.2em',
            }}
            maxRows={10}
          />
          <Button
            fullWidth
            variant='contained'
            color='primary'
            sx={{ m: 1 }}
            onClick={() => {
              if(titlu.trim().length > 0 && mesaj.trim().length > 0){
                props.makeRequest(props.modal.professor, titlu, mesaj);
                props.updateRequestModal({ active: false, professor: null });
              } else toast.error('Completează titlul și mesajul!');
            }}
          >
            Solicită
          </Button>
        </Box>
      </Grid>
    </Modal>
  );
}

export default RequestModal;
