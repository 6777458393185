import React from 'react';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { Autocomplete, Checkbox, FormControlLabel, RadioGroup } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();
function ProfProfile(props) {
  const grade = [
    'Asistent universitar',
    'Lector universitar',
    'Conferentiar universitar',
    'Profesor universitar',
  ];
  const [specializari, setSpecializari] = useState([]);
  const [grad, setGrad] = useState('Asistent universitar');
  const [chosen, setChosen] = useState([]);
  const [profSpec, setProfSpec] = useState([]);
  const SERVER = process.env.REACT_APP_SERVER;

  const getBackendData = () => {
    fetch(`${SERVER}specializare`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setSpecializari(data);
      });
    fetch(`${SERVER}user`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setGrad(data.instanta.grad_didactic);
        setProfSpec(data.spec.map((s) => s.id));
        setChosen(data.spec.map((s) => s.id))
      });
  };

  useEffect(getBackendData, [SERVER]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (verifyData() === true) {
      fetch(`${SERVER}user`, {
        method: 'PATCH',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          grad_didactic: grad,
          id_specializare: chosen,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          toast.success(`Date actualizate cu succes!`);
        })
        .catch((e) => {
          toast.error(`Actualizare esuata!`);
        });
    }
  };
  const verifyData = () => {
    if (grad === '' || !chosen.length || chosen.length === 0) {
      toast.warning('Te rugăm completează toate datele!');
      return false;
    }
    return true;
  }
  const specChecked = async (e) => {
    if (e.target.checked) {
      const previous = [...chosen];
      if (!previous.includes(parseInt(e.target.defaultValue)))
        previous.push(parseInt(e.target.defaultValue));
      setChosen(previous);
    } else {
      const previous = chosen.filter(function (value, index, arr) {
        return value != e.target.defaultValue;
      });
      setChosen(previous);
      const previous2 = profSpec.filter(function (value, index, arr) {
        return value != e.target.defaultValue;
      });
      setProfSpec(previous2);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container component='main' sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={3} />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
          {' '}
          <Box
            sx={{
              my: 8,
              mx: 6,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <lottie-player
              src='https://assets9.lottiefiles.com/packages/lf20_qudievat.json'
              background='transparent'
              speed='1'
              style={{ width: '100px', height: '100px' }}
              loop
              autoplay
            ></lottie-player>
            <Typography align='center' component='h1' variant='h5'>
              Editare date personale
            </Typography>
            <Box
              component='form'
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: '100%' }}
            >
              <Autocomplete
                disablePortal
                id='grad'
                options={grade}
                margin='normal'
                fullWidth
                value={grad}
                name='grad'
                label='Grad didactic'
                renderInput={(params) => (
                  <TextField
                    margin='normal'
                    fullWidth
                    {...params}
                    label='Grad didactic'
                  />
                )}
                inputValue={grad}
                onInputChange={(event, newInputValue) => {
                  setGrad(newInputValue);
                }}
              />
              <RadioGroup>
                <i>Specializare</i>
                {specializari && specializari.map(s => (
                  <FormControlLabel key={s.id} value={s.id}
                    control={<Checkbox
                      checked={profSpec.includes(parseInt(s.id)) || chosen.includes((parseInt(s.id)))}
                      value={s.id}
                      onClick={specChecked} />}
                    label={s.nume} />
                ))
                }
              </RadioGroup>
              <Button
                fullWidth
                type='submit'
                variant='contained'
                color='success'
                sx={{ mt: 3, mb: 2 }}
              >
                Salveaza
              </Button>
              <Button
                fullWidth
                type='button'
                variant='outlined'
                color='error'
                sx={{ mt: 3, mb: 2 }}
                onClick={() => props.setIsProfessorProfileVisible(false)}
              >
                Inapoi
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ProfProfile;