import React from 'react';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export default function MyLinearProgress({ current, max, style }) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...style,
      }}
    >
      <Box
        sx={{
          width: '100%',
          mr: 3,
          maxWidth: '300px',
        }}
      >
        <BorderLinearProgress
          variant='determinate'
          value={(current / max) * 100}
        />
      </Box>
      <Box
        sx={{
          minWidth: 35,
        }}
      >
        <Typography
          variant='body2'
          color='text.secondary'
        >{`${current}/${max}`}</Typography>
      </Box>
    </Box>
  );
}
