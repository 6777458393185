import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function MyAccordion({ title, data, buttons }) {
  return (
    <Accordion defaultExpanded
      style={{ marginTop: '20px', maxWidth: '700px', minWidth: '30vw' }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails expandicon={<ExpandMoreIcon />}>
        <List>
          {data.map((item, index) => {
            return (
              <React.Fragment key={`${title}-${index}`}>
                <ListItem>
                  <ListItemText
                    secondaryTypographyProps={{ component: 'span' }}
                    primary={item.primary}
                    secondary={
                      Array.isArray(item.secondary)
                        ? item.secondary.map((item, index) => (
                            <div key={`${data[0].secondary} ${index}`}>
                              {item}
                            </div>
                          ))
                        : item.secondary
                    }
                  />
                </ListItem>
                <Divider/>
              </React.Fragment>
            );
          })}
          {buttons && (
            <ListItem
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>{buttons}
            </ListItem>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
