import { useRef, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Grid, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

const MyDocument = (props) => {
  const componentRef = useRef();
  const today = new Date();
  const computeUniversitaryEar = () => {
    let inmatriculationYear, startingYear, endingYear = 0;
    const isInUniversitaryYear = (month, day) => {
      return today.getMonth() + 1 >= month && today.getDate() >= day;
    };
    if (isInUniversitaryYear(9, 1)) {
      inmatriculationYear = today.getFullYear() - 2;
      startingYear = today.getFullYear();
    } else {
      inmatriculationYear = today.getFullYear() - 3;
      startingYear = today.getFullYear() - 1;
    }
    endingYear = startingYear + 1;
    return { inmatriculationYear, startingYear, endingYear };
  };
  const universitaryYear = computeUniversitaryEar();

  const [specs, setSpecs] = useState([false, false, false]);
  useEffect(() => {
    let specializari = [false, false, false];
    specializari[props.student.id_specializare - 1] = true;
    setSpecs(specializari);
  }, [props.student.id_specializare]);

  let luna = parseInt(today.getMonth() + 1);
  if (luna.toString().length === 1)
    luna = '0' + parseInt(today.getMonth() + 1);
  let zi = parseInt(today.getDate());
  if (zi.toString().length === 1)
    zi = '0' + parseInt(today.getDate());


  return (
    <div>
      <Grid container justifyContent='center'>
        {
           props.titlu && props.titlu.length > 0 ? (
            <div></div>
          ) : (<Typography align="center" sx={{ mt: 2, mb: 1 }} component="p" variant="p">
            Introdu titlul pentru lucrare și salvează-l.
          </Typography>)}
        <Button
          sx={{ mb: 3, mt: 1 }}
          type='button'
          disabled={props.titlu.length > 0 ? false : true}
          fullWidth
          variant='contained'
          color='primary'
          onClick={useReactToPrint({
            content: () => componentRef.current,
            documentTitle: 'Cerere licență - ' +props.student.nume + ' ' + props.student.prenume,
          })
          }
        >
          Generare cerere
        </Button>
      </Grid>
      <div className='yourClassName' ref={componentRef}>
        <div
          style={{
            fontFamily: 'Times New Roman',
            fontSize: '12px',
            color: 'black',
            background: 'white',
            paddingLeft: '2cm',
            paddingRight: '2cm',
            paddingTop: '1.5cm'
          }}
        >
          <div
            style={{ fontSize: '16px', textAlign: 'center', color: '#2e2be2' }}
          >
            <img
              id='csie'
              src='https://csie.ase.ro/wp-content/uploads/2020/10/cropped-CSIE_new-300x132.png'
              alt='Logo'
            />
            Facultatea de Cibernetică, Statistică și <br></br>Informatică
            Economică <br></br>
            Academia de Studii Economice din București<br></br>
            <p
              style={{
                fontSize: '14px',
                textAlign: 'center',
                color: '#1a17a9',
                marginLeft: '130px',
              }}
            >
              Clădirea Virgil Madgearu Calea Dorobanți, 15-17, Sector 1,
              <br></br>
              Bucureşti, 010552, http://csie.ase.ro<br></br>
            </p>
          </div>
          <br></br>
          <div style={{ fontSize: '16px', textAlign: 'center' }}>
            Aviz Decan, &emsp;&emsp;&emsp; &emsp;&emsp;&emsp;&emsp;&emsp;Aviz
            Director Departament, &emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;De acord, __________________
            &emsp;&emsp;&emsp;&emsp;&emsp;____________________ &emsp;&emsp;
            &emsp;__________________ <br></br>
            &emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            &emsp;&emsp;&emsp;&emsp;&emsp;(semnătura coordonatorului și data)
            <br></br>
          </div>
          <br></br>
          <p style={{ fontSize: '20px', textAlign: 'center' }}>
            <b>Domnule Decan,</b>
          </p>
          <br></br>
          <div style={{ fontSize: '16px', textAlign: 'left' }}>
            Subsemnatul/a &emsp;
            <u> {props.student.nume + ' ' + props.student.prenume} </u>&emsp;
            student/ă în {props.student.an}, în cadrul Facultății de
            Cibernetică, Statistică și Informatică Economică, forma de
            învățământ¹ <u>{props.student.forma_invatamant}</u>, specializarea/programul:
            <br></br>
            <ul style={{ listStyleType: 'none', fontWeight: 'bold' }}>
              <li>
                <input type='checkbox' readOnly checked={specs[0]}></input>
                &emsp;Informatică Economică
              </li>
              <li>
                <input type='checkbox' readOnly checked={specs[1]}></input>
                &emsp;Cibernetică Economică
              </li>

              <li>
                <input type='checkbox' readOnly checked={specs[2]}></input>
                &emsp;Informatică Economică (cu predare în limba engleză)
              </li>
              <li>
                <input type='checkbox'></input>&emsp;Statistică și Previziune
                Economică
              </li>
            </ul>
            vă rog să binevoiți a-mi aproba realizarea lucrării de licență cu
            titlul:<br></br>
            <u>{props.titlu}</u>
            <br></br>
            având coordonator științific pe dl/dna.(grad didactic): 
            <u>
              {props.professor &&
                ' '+  props.professor.grad_didactic +
                ' ' +
                props.professor.nume +
                ' ' +
                props.professor.prenume}
            </u>
            <br></br>
            Menționez că am fost înmatriculat/ă în programul de licență în anul²{' '}
            {props.student.an_inmatriculare}
            <br></br>
            e-mail <u>{props.student.email}</u>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            telefon {props.student.telefon}
            <br></br>
            <br></br>
            <br></br>
            Data{' '}
            <u>
              {zi +
                '.' +
                luna +
                '.' +
                today.getFullYear()}
            </u>
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Semnătura
            student,<br></br>
            <br></br>
            <br></br>
            Domnului Decan al Facultății de Cibernetică, Statistică și
            Informatică <br></br>
            Economică <br></br>
            <br></br>_______________
          </div>
          <div style={{ fontSize: '14px', textAlign: 'left' }}>
            ¹IF-pentru învățământ cu frecvență, ID pentru învățământ la distanță
            <br></br>
            ²Pentru studenții din anul 3, anul universitar {universitaryYear.startingYear}-{universitaryYear.endingYear}, anul
            înmatriculării este {universitaryYear.inmatriculationYear}.<br></br>
            <br></br>
            *datele de contact sunt folosite exclusive pentru notificarea
            studenților în problem ce privesc pregătirea și derularea examenului
            de licență.
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocument;
