import React, { useState, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import TextField from '@mui/material/TextField';
import MyDocument from '../components/Document';
import RequestModal from '../components/RequestModal';
import DownloadIcon from '@mui/icons-material/Download';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '../ui/Modal';
import TabelResponsive from '../components/TabelResponsive';

const theme = createTheme();
function StudentPage() {
  const [cdlp, setCdlp] = useState(false);
  const SERVER = process.env.REACT_APP_SERVER;
  const [file, setFile] = useState();
  const [canGenerate, setCanGenerate] = useState(false);
  const [cerereSemnata, setCerereSemnata] = useState(false);
  const [areCerere, setAreCerere] = useState(false);
  const [professor, setProfessor] = useState();
  const [availableProfessors, setAvailableProfessors] = useState([]);
  const [student, setStudent] = useState();
  const [solicitare, setSolicitare] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [titluCerere, setTitluCerere] = useState('');
  const [requestModal, setRequestModal] = useState({
    active: false,
    professor: null,
  });
  const [hasDeletedRequest, setHasDeletedRequest] = useState(false);

  const [titluIncarcare, setTitluIncarcare] = useState('');

  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSolicita = (id) => {
    setRequestModal({
      active: true,
      professor: availableProfessors.filter((prof) => prof.id === id)[0],
    });
  };

  const verificaCerere = () => {
    fetch(SERVER + 'cerere/titlu', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      }
    })
      .then((res) => {
        if (!res.ok) {
          toast.error(`Eșuare încărcare!`);
        }
        return res.json();
      })
      .then((data) => {if(data && data.titlu && data.titlu.titlu) {setTitluIncarcare(data.titlu.titlu)}});
  }

  const getBackendData = () => {
    verificaCerere()
    const areCerereSemnata = async (student) => {
      const response = await fetch(`${SERVER}cerere`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      });
      const data = await response.json();
      if (data && data.data && data.data.e_semnat === 1) setCerereSemnata(true);
      if (data && data.data) setAreCerere(true);
    };
    fetch(SERVER + 'user', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const std = data;
        setStudent(std);
        if (std.e_repartizat) {
          areCerereSemnata(std);
        }
        if (std.poateTrimite) {
          fetch(SERVER + 'user/profesori', {
            headers: {
              Authorization: localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
            })
            .then((data) => {
              if(data && data.listaProfesori)
              setAvailableProfessors(data.listaProfesori);
            });
        }
        if (!std.poateTrimite) {
          fetch(SERVER + 'solicitare/status', {
            headers: {
              Authorization: localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              }
              throw new Error('eroare');
            })
            .then((data) => {
              setSolicitare(data.solicitare);
              fetch(`${SERVER}user/profesor/${data.solicitare.id_profesor}`, {
                headers: {
                  Authorization: localStorage.getItem('token'),
                  'Content-Type': 'application/json',
                },
              })
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  }
                  throw new Error('eroare');
                })
                .then((data) => {
                  setProfessor(data.data);
                });
            })
            .catch(() => alert('error'));
        }
      });
  };

  const makeRequest = (prof, title, message) => {
    fetch(SERVER + 'solicitare', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        idProfesor: prof.id,
        titlu: title,
        mesaj: message,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(SERVER + 'solicitare/status', {
          headers: {
            Authorization: localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error('eroare');
          })
          .then((data) => {
            setSolicitare(data.solicitare);
          });
      })
      .catch((e) => e.message());
    setStudent((prevState) => {
      const newState = { ...prevState };
      newState.poateTrimite = false;
      return newState;
    });
    setProfessor(prof);
  };

  const onFileChange = (e) => {
    const uploadedFile = e.target.files[0]
    if (uploadedFile.size / 1024 / 1024 > 15) {
      toast.error('Fișierul este prea mare!');
      e.target.value = null;
      return;
    }
    const name = uploadedFile.name;
    const parts = name.split(".");
    const extension = parts[parts.length - 1];
    if(extension !== "pdf"){
      toast.error("Puteți încărca doar pdf!");
      return;
    }
    setFile(uploadedFile);
  };

  const onUpload = () => {
    if (!file) {
      toast.error('Alege fișierul pe care vrei să îl încarci!');
      return;
    }
    if (titluIncarcare.length === 0) {
      return toast.error('Titlul nu poate fi nul');
    }
    const fileReader = new FileReader();
    let base64;
    fileReader.onload = (e) => {
      base64 = e.target.result;
      fetch(SERVER + 'cerere', {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          titlu: titluIncarcare,
          fisier: base64,
          idProfesor: professor.id,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            toast.error(`Eșuare încărcare!`);
          }
          if (res.ok) {
            toast.success(`Încărcat cu succes!`);
            setAreCerere(true);
            setCerereSemnata(false);
          }

          return res.json();
        })
        .then((data) => {});
    };
    fileReader.readAsDataURL(file);
  };
  const onDownload = () => {
    fetch(SERVER + 'cerere', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!data?.data?.fisier) {
          toast.error('Nu ai cererea incarcata');
          return;
        }
        const downloadLink = document.createElement('a');
        const fileName = `Cerere licenta ${
          student.nume + ' ' + student.prenume
        } - ${data.data.titlu}.pdf`;
        downloadLink.href = data.data.fisier;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  useEffect(getBackendData, [SERVER]);

  const adaugaCerere = () => {
    fetch(SERVER + 'cerere/titlu', {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        titlu: titluCerere
      }),
    })
      .then((res) => {
        if (!res.ok) {
          toast.error(`Eșuare încărcare!`);
        }
        if (res.ok) {
          toast.success(`Ai salvat titlul: ${titluCerere}`);
          setCanGenerate(true);
        }
        return res.json();
      })
      .then((data) => {verificaCerere()});
  }

  const updateRequestModal = (modal) => {
    setRequestModal({ ...modal });
  };

  const cancelRequest = async (e) => {
    e.preventDefault();
    const res = await fetch(SERVER + 'solicitare', {
      method: 'DELETE',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idSolicitare: solicitare.id }),
    });
    const data = await res.json();
    if (data.error) setDeleteError(data.error);
    if (!data.error) getBackendData();
  };

  return (
    <ThemeProvider theme={theme}>
      {cdlp && (
        <div className='cdlp'>Cartel de los Proyectos Rulz!!!!💰💰💰💰</div>
      )}
      <Grid container component='main' sx={{ height: '94vh' }}>
        <CssBaseline />
        <Grid
          item
          sx={{ backgroundColor: 'aliceblue', width: '100vw' }}
          component={Paper}
          elevation={6}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Typography
                // fontFamily={'Lucida Handwriting'}
                align='center'
                component='h4'
                variant='h4'
                sx={{ mb: 3 }}
              >
                Bine ai venit, {student && student.nume + ' ' + student.prenume}
                <span
                  onClick={(e) => {
                    if (e.detail === 3) setCdlp(true);
                  }}
                >
                  .
                </span>
              </Typography>
              {student && student?.e_repartizat !== 0 && (
                <div>
                  <Typography
                    align='center'
                    sx={{ mb: 5 }}
                    component='h5'
                    variant='h5'
                  >
                    Profesorul tău coordonator este{' '}
                    {professor && professor.nume + ' ' + professor.prenume}.
                  </Typography>
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs
                        value={value}
                        allowScrollButtonsMobile
                        scrollButtons
                        selectionFollowsFocus
                        variant='fullWidth'
                        onChange={handleChange}
                      >
                        <Tab
                          wrapped
                          label={
                            <div>
                              <Typography display='inline' align='center'>
                                1. Generează cererea
                              </Typography>
                            </div>
                          }
                          value='1'
                        />
                        <Tab
                          wrapped
                          label={
                            <Typography display='inline' align='center'>
                              2. Încarcă cererea
                            </Typography>
                          }
                          value='2'
                        />
                        <Tab
                          wrapped
                          label={
                            <div>
                              <Typography display='inline' align='center'>
                                3. Cererea ta semnată
                              </Typography>
                            </div>
                          }
                          value='3'
                        />
                      </Tabs>
                    </Box>
                    <TabPanel value='1'>
                      <Grid container component='main'>
                        <Grid item xs={false} sm={1} md={2} />
                        <Grid item xs={12} sm={10} md={8} elevation={6}>
                          <Box
                            sx={{
                              my: 8,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              align='center'
                              component='p'
                              variant='p'
                              className='text-warning'
                              sx={{mb:2}}
                            >
                              Solicitarea ta a fost cu titlul: <i>{solicitare && solicitare.titlu}</i>.
                            </Typography>
                            <Typography
                              align='center'
                              component='p'
                              variant='p'
                              className='text-warning'
                            >
                              Introdu titlul licenței, generează cererea
                              precompletată și semneaz-o.
                            </Typography>
                            <TextField
                              margin='normal'
                              fullWidth
                              type='text'
                              placeholder='Titlul licentei'
                              value={titluCerere}
                              onChange={(e) => {
                                setTitluCerere(e.target.value);
                              }}
                            />
                            {titluIncarcare ? <Typography
                              align='center'
                              component='p'
                              variant='p'
                              className='text-warning'
                            >
                              Ai salvat deja titlul '{titluIncarcare}'.
                            </Typography> : <Typography
                              align='center'
                              component='p'
                              variant='p'
                              color="red"
                              className='text-danger'
                            >
                              Nu ai salvat niciun titlu.
                            </Typography> }
                            <Button disabled={titluCerere.length < 1} style={{marginBottom: "25px", marginTop: "10px"}} onClick={adaugaCerere} variant='contained'>Salvează titlul</Button>
                            <MyDocument
                              className='doc'
                              student={student}
                              professor={professor}
                              titlu={titluIncarcare}
                            ></MyDocument>
                            <lottie-player
                              src='https://assets1.lottiefiles.com/packages/lf20_jxgqawba.json'
                              background='transparent'
                              speed='1'
                              style={{ width: '200px', height: '200px' }}
                              loop
                              autoplay
                            ></lottie-player>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value='2'>
                      <Grid container component='main'>
                        <Grid item xs={false} sm={1} md={2} />
                        <Grid item xs={12} sm={10} md={8} elevation={6}>
                          <Box
                            sx={{
                              my: 8,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <div className='text-warning'>
                              Introdu titlul lucrării de licență.
                            </div>
                            <TextField
                              required
                              margin='normal'
                              type='text'
                              fullWidth
                              placeholder='Titlul licentei'
                              value={titluIncarcare}
                              onChange={(e) =>
                                setTitluIncarcare(e.target.value)
                              }
                            />
                            <Typography
                              className='text-warning'
                              align='center'
                              component='p'
                              variant='p'
                              sx={{ mt: 3 }}
                            >
                              Încarcă aici cererea.
                            </Typography>
                            <TextField
                              margin='normal'
                              type='file'
                              accept='.pdf'
                              required
                              fullWidth
                              onChange={onFileChange}
                            />
                            <Button
                              type='button'
                              variant='contained'
                              color='success'
                              sx={{ mt: 3, mb: 3 }}
                              onClick={onUpload}
                              disabled={
                                titluIncarcare.length === 0 ||
                                !file ||
                                (!cerereSemnata && areCerere)
                              }
                            >
                              Încarcă cererea
                            </Button>
                            {!cerereSemnata && areCerere && (
                              <Typography
                                className='text-warning'
                                align='center'
                                component='p'
                                variant='p'
                                sx={{ mt: 3 }}
                              >
                                Ai încărcat deja o cerere, așteaptă să fie
                                semnată.
                              </Typography>
                            )}
                            <lottie-player
                              src='https://assets8.lottiefiles.com/packages/lf20_l9v6w90m.json'
                              background='transparent'
                              speed='0.4'
                              style={{ width: '300px', height: '300px' }}
                              loop
                              autoplay
                            ></lottie-player>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value='3'>
                      {student && student?.e_repartizat && cerereSemnata ? (
                        <Box
                          sx={{
                            my: 4,
                            mx: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          {' '}
                          <div>
                            Cererea ta {cerereSemnata ? '' : 'nu'} este semnată de către profesorul coordonator.
                          </div>
                          <IconButton
                            sx={{ mt: 8, mb: 3 }}
                            color='primary'
                            onClick={onDownload}
                          >
                            Descarcă cererea <DownloadIcon />
                          </IconButton>
                          <lottie-player
                            src='https://assets5.lottiefiles.com/packages/lf20_w9yhybo7.json'
                            background='transparent'
                            speed='1'
                            style={{ width: '300px', height: '300px' }}
                            loop
                            autoplay
                          ></lottie-player>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            my: 4,
                            mx: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          Cererea ta {cerereSemnata ? '' : 'nu'} este semnată de
                          către profesorul coordonator.
                          <lottie-player
                            src='https://assets5.lottiefiles.com/packages/lf20_jogp4tqe.json'
                            background='transparent'
                            speed='0.6'
                            style={{ width: '300px', height: '300px' }}
                            loop
                            autoplay
                          ></lottie-player>
                        </Box>
                      )}
                    </TabPanel>
                  </TabContext>
                </div>
              )}
            </Box>
            <Box
              noValidate
              sx={{
                mt: 1,
                width: '80vw',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {student?.e_repartizat === 0 && !student?.poateTrimite ? (
                <>
                  <Typography
                    sx={{ mt: 6 }}
                    align='center'
                    component='p'
                    variant='p'
                  >
                    Ai facut o solicitare profesorului{' '}
                    {professor && professor.nume + ' ' + professor.prenume}. Te
                    rugăm să aștepți un răspuns din partea profesorului. Succes!
                  </Typography>
                  <lottie-player
                    src='https://assets8.lottiefiles.com/private_files/lf30_rgvpmzja.json'
                    background='transparent'
                    speed='1'
                    style={{ width: '300px', height: '300px' }}
                    loop
                    autoplay
                  ></lottie-player>
                  {deleteError && (
                    <Typography
                      sx={{ mt: 3 }}
                      align='center'
                      component='p'
                      variant='p'
                      color='error'
                    >
                      {deleteError}
                    </Typography>
                  )}
                  <Typography
                    sx={{ mt: 3 }}
                    align='center'
                    component='p'
                    variant='p'
                    className='text-warning'
                  >
                    Dacă nu primești răspuns în termen de 2 zile, ai
                    posibilitatea să soliciți colaborarea unui alt profesor
                    disponibil.
                  </Typography>
                  <Button
                    type='button'
                    variant='contained'
                    color='primary'
                    sx={{ mt: 3, mb: 3 }}
                    onClick={() => setHasDeletedRequest(true)}
                  >
                    Retrage solicitarea
                  </Button>
                  {hasDeletedRequest && (
                    <Modal
                      open={true}
                      closed={() => setHasDeletedRequest(false)}
                    >
                      <Grid>
                        <Typography
                          align='center'
                          component='h5'
                          variant='h5'
                          sx={{ mb: 2 }}
                        >
                          Ești sigur că vrei să îți retragi solicitarea de
                          colaborare?
                        </Typography>
                        <Grid>
                          <Button
                            variant='contained'
                            color='success'
                            sx={{ width: '48%', mx: '1%' }}
                            onClick={(e) => {
                              cancelRequest(e);
                              setHasDeletedRequest(false);
                            }}
                          >
                            Da
                          </Button>
                          <Button
                            variant='contained'
                            color='error'
                            sx={{ width: '48%', mx: '1%' }}
                            onClick={() => setHasDeletedRequest(false)}
                          >
                            Nu
                          </Button>
                        </Grid>
                      </Grid>
                    </Modal>
                  )}
                </>
              ) : (
                ''
              )}
              {student?.poateTrimite ? (
                <>
                  {!student.grupa ||
                  !student.id_specializare ||
                  !student.an_inmatriculare ||
                  !student.telefon ? (
                    <Typography
                      align='center'
                      sx={{ mt: 8, mb: 1 }}
                      component='h5'
                      variant='h5'
                      color='error'
                    >
                      Completați datele personale în pagina de profil, din
                      colțul dreapta sus. <b>Toate câmpurile sunt obligatorii!</b>
                    </Typography>
                  ) : (
                    <Grid
                      container
                      flexDirection={'column'}
                      alignItems='center'
                    >
                      <Grid item xs={12}>
                        <Typography
                          align='center'
                          sx={{ mt: 5, mb: 3 }}
                          component='h5'
                          variant='h5'
                        >
                          Solicită colaborarea unuia dintre profesorii
                          disponibili de mai jos.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          align='center'
                          component='p'
                          variant='p'
                          className='text-warning'
                          sx={{ mb: 3 }}
                        >
                          Odată lansată solicitarea, nu o poți retrage decât
                          peste 2 zile.
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {availableProfessors &&
                        availableProfessors.length > 0 ? (
                          <TabelResponsive
                            columns={[
                              { label: 'Nume', id: 'nume' },
                              { label: 'Locuri', id: 'numarLocuri' },
                              { label: '', id: 'button' },
                            ]}
                            rows={availableProfessors.map((prof) => ({
                              id: prof.id,
                              nume: `${prof.nume} ${prof.prenume}`,
                              numarLocuri: prof.numarLocuriRamase,
                            }))}
                            handler={handleSolicita}
                            title='Profesori'
                          />
                        ) : (
                          <Typography
                            align='center'
                            sx={{ mt: 8, mb: 1 }}
                            component='h5'
                            variant='h5'
                            color='error'
                          >
                            Nu mai sunt profesori disponibili pentru
                            specializarea ta. Directorul de departament te va
                            repartiza manual.
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {requestModal.active && (
                    <RequestModal
                      makeRequest={makeRequest}
                      updateRequestModal={updateRequestModal}
                      modal={requestModal}
                    />
                  )}
                </>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default StudentPage;
