import { useState, useEffect, useRef } from 'react';
import Modal from '../ui/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import { Box, Grid, TextField } from '@mui/material';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TabContext from '@mui/lab/TabContext';
import CssBaseline from '@mui/material/CssBaseline';
import Tabs from '@mui/material/Tabs';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TabPanel from '@mui/lab/TabPanel';
import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import MyAccordion from '../components/MyAccordion';
import MyLinearProgress from '../components/MyLinearProgress';

const theme = createTheme();
function ProfessorPage() {
  const SERVER = process.env.REACT_APP_SERVER;
  const [file, setFile] = useState();
  const [specializari, setSpecializari] = useState([]);
  const [professor, setProfessor] = useState();
  const [acceptedStudents, setAcceptedStudents] = useState([]);
  const [possibleStudents, setPossibleStudents] = useState([]);
  const [toSign, setToSign] = useState([]);
  const [acceptRefuseModal, setAcceptRefuseModal] = useState({
    active: false,
    btn: null,
  });
  const [uploadModal, setUploadModal] = useState({
    active: false,
    student: null,
  });

  const [value, setValue] = useState('1');
  const motivRef = useRef();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getBackendData = () => {
    setToSign([]);
    fetch(SERVER + 'user', {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then(res => res.json())
      .then(data => {
        setProfessor(data.instanta);
        fetch(SERVER + 'solicitare', {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
          .then(res => {
            if (res.ok) {
              return res.json();
            }
          })
          .then(data => {
            const solicitariInAsteptare = data.solicitari.filter(
              sol => sol.e_acceptata === -1
            );
            const solicitariAcceptate = data.solicitari.filter(
              sol => sol.e_acceptata === 1
            );
            fetch(SERVER + 'cerere/', {
              method: 'GET',
              headers: {
                Authorization: localStorage.getItem('token'),
                'Content-Type': 'application/json',
              },
            })
              .then(res => {
                if (res.ok) {
                  return res.json();
                }
              })
              .then(data => {
                const cereri = data.cereri;
                fetch(SERVER + 'user/studenti', {
                  method: 'GET',
                  headers: {
                    Authorization: localStorage.getItem('token'),
                    'Content-Type': 'application/json',
                  },
                })
                  .then(res => {
                    if (res.ok) {
                      return res.json();
                    }
                  })
                  .then(data => {
                    const studenti = data.data;
                    const posStud = studenti.filter(stud => {
                      for (const sol of solicitariInAsteptare) {
                        if (sol.id_student === stud.id) {
                          stud.solicitare = sol;
                          return true;
                        }
                      }
                      return false;
                    });
                    const accStud = studenti.filter(stud => {
                      stud.cereri = [];
                      for (const sol of solicitariAcceptate) {
                        if (sol.id_student === stud.id) {
                          stud.solicitare = sol;
                          for (const cerere of cereri) {
                            if (cerere.id_student === stud.id) {
                              stud.cereri.push(cerere);
                            }
                          }
                          return true;
                        }
                      }
                      return false;
                    });
                    const toSignStud = studenti.filter(stud => {
                      for (const cerere of cereri) {
                        if (
                          cerere.id_student === stud.id &&
                          cerere.e_semnat !== 1
                        ) {
                          stud.cerere = cerere;
                          return true;
                        }
                      }
                      return false;
                    });
                    for (const stud of accStud) {
                      checkFile(stud).then(res => {
                        stud.titluCerere = res;
                      });
                    }
                    setToSign(toSignStud);
                    setAcceptedStudents(accStud);
                    setPossibleStudents(posStud);
                    fetch(SERVER + 'specializare/', {
                      method: 'GET',
                      headers: {
                        Authorization: localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                      },
                    })
                      .then(res => {
                        if (res.ok) {
                          return res.json();
                        }
                      })
                      .then(data => {
                        setSpecializari(data);
                      });
                  });
              });
          })
          .catch(e => console.log(e.message));
      });
  };

  const sendRequest = (studentId, isAccepted) => {
    fetch(SERVER + 'solicitare', {
      method: 'PATCH',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ idStudent: studentId, raspuns: isAccepted }),
    })
      .then(res => {
        if (res.ok) {
          return res.json();
        }
      })
      .then(data => { });
  };

  const onDownload = student => {
    fetch(`${SERVER}cerere/${student.id}`, {
      method: 'GET',
      headers: {
        Authorization: localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (!data?.fisier) {
          alert('Nu există fișier încărcat!');
          return;
        }
        const downloadLink = document.createElement('a');
        const fileName = `Cerere licență ${student.nume + ' ' + student.prenume
          } - ${data.titlu}.pdf`;
        downloadLink.href = data.fisier;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  };

  const checkFile = async student => {
    const response = await fetch(`${SERVER}cerere/${student.id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    const data = await response.json();
    if (data.fisier) return data.titlu;
    return false;
  };

  const onUpload = student => {
    setUploadModal({
      active: true,
      student: student,
    });
  };

  const onFileChange = e => {
    if (e.target.files[0].size / 1024 / 1024 > 15) {
      alert('File too big');
      e.target.value = null;
      return;
    }
    setFile(e.target.files[0]);
  };

  const refuzaCererea = async (student, motiv) => {
    if (motiv) {
      fetch(SERVER + 'user/mail', {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_student: student.id, text: motiv }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    }
    const res = await fetch(`${SERVER}cerere/${student.id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    });
    const cerere = await res.json();
    const response = await fetch(`${SERVER}cerere/${cerere.id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
      method: 'DELETE',
    });

    const data = await response.json();
    if (!response.ok) {
      console.log(data.error);
      return;
    }
    toast.info('Ați refuzat cererea!');
    getBackendData();
  };

  useEffect(getBackendData, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          sx={{ backgroundColor: 'aliceblue', width: '100vw' }}
          component={Paper}
          elevation={6}
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Typography
                align="center"
                component="h4"
                variant="h4"
                sx={{ mb: 3 }}
              >
                Bine ai venit,{' '}
                {professor && professor.nume + ' ' + professor.prenume}
              </Typography>
              {professor &&
                (!professor.grad_didactic) && (
                  <Typography
                    align="center"
                    sx={{ mt: 3, mb: 6 }}
                    component="h5"
                    variant="h5"
                    color="error"
                  >
                    Completați datele personale în pagina de profil, din colțul
                    dreapta sus.
                  </Typography>
                )}
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={value}
                    allowScrollButtonsMobile
                    scrollButtons
                    selectionFollowsFocus
                    variant="fullWidth"
                    onChange={handleChange}
                  >
                    <Tab
                      wrapped
                      label={
                        <div>
                          <Typography display="inline" align="center">
                            Colaborări în așteptare
                          </Typography>{' '}
                          &emsp;{' '}
                          {possibleStudents.length > 0 && (
                            <Typography
                              backgroundColor="white"
                              display="inline"
                              color="primary"
                              fontFamily={'Lucida Handwriting'}
                            >
                              <b>{possibleStudents.length}</b>
                            </Typography>
                          )}
                        </div>
                      }
                      value="1"
                    />
                    <Tab
                      wrapped
                      label={
                        <Typography display="inline" align="center">
                          Studenți acceptați
                        </Typography>
                      }
                      value="2"
                    />
                    <Tab
                      wrapped
                      label={
                        <div>
                          <Typography display="inline" align="center">
                            Cereri de semnat
                          </Typography>{' '}
                          &emsp;{' '}
                          {toSign.length > 0 && (
                            <Typography
                              backgroundColor="white"
                              display="inline"
                              color="primary"
                              fontFamily={'Lucida Handwriting'}
                            >
                              <b>{toSign.length}</b>
                            </Typography>
                          )}
                        </div>
                      }
                      value="3"
                    />
                  </Tabs>
                </Box>
                <TabPanel value="1">
                  <Grid container flexDirection={'column'} alignItems="center">
                    <Grid flexDirection={'column'} alignItems="center">
                      <p>
                        Locuri disponibile:{' '}
                        {professor?.numar_studenti_maxim -
                          acceptedStudents.length}
                      </p>
                      <MyLinearProgress
                        current={acceptedStudents.length}
                        max={professor?.numar_studenti_maxim}
                        style={{ marginBottom: '20px' }}
                      />
                    </Grid>
                    {possibleStudents.length === 0 && (
                      <Grid item xs={12}>
                        <Typography
                          align="left"
                          component="p"
                          textAlign="center"
                          sx={{ mb: 2 }}
                          variant="p"
                        >
                          Nu aveți colaborări în așteptare.
                        </Typography>
                      </Grid>
                    )}
                    {possibleStudents.length !== 0 && (
                      <>
                        <Grid item xs={12} textAlign="center">
                          Studenți în așteptare: {possibleStudents.length}
                        </Grid>
                        <Container sx={{ py: 0, mt: 5, mb: 5 }}>
                          <Grid
                            container
                            spacing={1}
                            sx={{ py: 0, mt: 1, mb: 5 }}
                          >
                            {possibleStudents &&
                              possibleStudents.map((stud, index) => (
                                <Grid
                                  item
                                  key={stud.id}
                                  xs={false}
                                  sm={false}
                                  md={6}
                                >
                                  <MyAccordion
                                    title={`${index + 1}. ${stud.nume} ${stud.prenume
                                      } `}
                                    data={[
                                      {
                                        primary: 'E-mail:',
                                        secondary: stud.email,
                                      },
                                      {
                                        primary: 'Titlu:',
                                        secondary: stud.solicitare.titlu,
                                      },
                                      {
                                        primary: 'Mesaj:',
                                        secondary: stud.solicitare.mesaj,
                                      },
                                      {
                                        primary: 'Specializare:',
                                        secondary:
                                          specializari &&
                                            specializari[
                                            stud.id_specializare - 1
                                            ] &&
                                            specializari[stud.id_specializare - 1]
                                              .nume
                                            ? specializari[
                                              stud.id_specializare - 1
                                            ].nume
                                            : '',
                                      },
                                      { primary: 'An:', secondary: stud.an },
                                      {
                                        primary: 'Grupa:',
                                        secondary: stud.grupa,
                                      },
                                    ]}
                                    buttons={
                                      <>
                                        <Button
                                          color="success"
                                          startIcon={<CheckCircleIcon />}
                                          onClick={() => {
                                            document.documentElement.scrollTop = 0;
                                            setAcceptRefuseModal({
                                              active: true,
                                              btn: 'acceptați',
                                              student: stud,
                                            });
                                          }}
                                        >
                                          Acceptă
                                        </Button>
                                        <Button
                                          color="error"
                                          startIcon={<CancelIcon />}
                                          onClick={() => {
                                            document.documentElement.scrollTop = 0;
                                            setAcceptRefuseModal({
                                              active: true,
                                              btn: 'Refuză',
                                              student: stud,
                                            });
                                          }}
                                        >
                                          Refuză
                                        </Button>
                                      </>
                                    }
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </Container>
                      </>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid container flexDirection={'column'} alignItems="center">
                    <Container sx={{ py: 0 }}>
                      <Grid container spacing={1} sx={{ py: 0 }}>
                        {acceptedStudents &&
                          acceptedStudents.map((stud, index) => (
                            <Grid
                              item
                              key={stud.id}
                              xs={false}
                              sm={false}
                              md={6}
                            >
                              <MyAccordion
                                title={`${index + 1}. ${stud.nume} ${stud.prenume
                                  }`}
                                data={[
                                  { primary: 'E-mail:', secondary: stud.email },
                                  {
                                    primary: 'Specializare:',
                                    secondary:
                                      specializari &&
                                        specializari[stud.id_specializare - 1] &&
                                        specializari[stud.id_specializare - 1]
                                          .nume
                                        ? specializari[stud.id_specializare - 1]
                                          .nume
                                        : '',
                                  },
                                  { primary: 'Grupa:', secondary: stud.grupa },
                                  {
                                    primary: 'Titlu solicitare:',
                                    secondary: stud.solicitare.titlu,
                                  },
                                  {
                                    primary: 'Titlu cereri:',
                                    secondary:
                                      stud.cereri && stud.cereri.length > 0
                                        ? stud.cereri.map((cer, i) =>
                                          cer.e_semnat
                                            ? i + 1 + '. ' + cer.titlu + '; '
                                            : ''
                                        )
                                        : 'Nu are cereri.',
                                  },
                                  { primary: 'An:', secondary: stud.an },
                                ]}
                                buttons={
                                  stud.titluCerere ? (
                                    <>
                                      <Button
                                        startIcon={<DownloadIcon />}
                                        onClick={() => {
                                          onDownload(stud, index);
                                        }}
                                      >
                                        Descarcă
                                      </Button>

                                      <Button
                                        startIcon={<UploadFileIcon />}
                                        onClick={() => {
                                          document.documentElement.scrollTop = 0;
                                          onUpload(stud);
                                        }}
                                      >
                                        Încarcă cererea semnată
                                      </Button>
                                    </>
                                  ) : (
                                    <span>Nu a încărcat cerere</span>
                                  )
                                }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Container>
                    {acceptedStudents?.length === 0 && (
                      <Grid item xs={12}>
                        Nu aveți studenți acceptați deocamdată.
                      </Grid>
                    )}
                    {acceptedStudents?.length > 0 &&
                      acceptedStudents.map((stud, index) => {
                        return <Grid item xs={12} key={stud.id}></Grid>;
                      })}
                  </Grid>
                </TabPanel>
                <TabPanel value="3">
                  <Grid container flexDirection={'column'} alignItems="center">
                    <Container sx={{ py: 0 }}>
                      <Grid container spacing={1} sx={{ py: 0 }}>
                        {toSign &&
                          toSign.map((stud, index) => (
                            <Grid
                              item
                              key={stud.id}
                              xs={false}
                              sm={false}
                              md={6}
                            >
                              <MyAccordion
                                title={`${index + 1}. ${stud.nume} ${stud.prenume
                                  }`}
                                data={[
                                  { primary: 'E-mail:', secondary: stud.email },
                                  { primary: 'Grupa:', secondary: stud.grupa },
                                  {
                                    primary: 'Titlu:',
                                    secondary:
                                      stud.cereri[stud.cereri?.length - 1]
                                        ?.titlu,
                                  },
                                  { primary: 'An:', secondary: stud.an },
                                ]}
                                buttons={
                                  stud.titluCerere ? (
                                    <>
                                      <Button
                                        color="error"
                                        startIcon={<CancelIcon />}
                                        onClick={e => {
                                          e.preventDefault();
                                          setAcceptRefuseModal({
                                            active: true,
                                            btn: 'Refuză',
                                            cerere: true,
                                            student: stud,
                                          });
                                        }}
                                      >
                                        Refuză
                                      </Button>
                                      <Button
                                        startIcon={<DownloadIcon />}
                                        onClick={() => {
                                          onDownload(stud, index);
                                        }}
                                      >
                                        Descarcă
                                      </Button>

                                      <Button
                                        startIcon={<UploadFileIcon />}
                                        onClick={() => {
                                          document.documentElement.scrollTop = 0;
                                          onUpload(stud);
                                        }}
                                      >
                                        Încarcă cererea semnată
                                      </Button>
                                    </>
                                  ) : (
                                    <span>Nu a încărcat cerere</span>
                                  )
                                }
                              />
                            </Grid>
                          ))}
                      </Grid>
                    </Container>
                    {toSign?.length === 0 && (
                      <Grid item xs={12}>
                        Nu aveți cereri de semnat deocamdată.
                      </Grid>
                    )}
                    {toSign?.length > 0 &&
                      toSign.map((stud, index) => {
                        return <Grid item xs={12} key={stud.id}></Grid>;
                      })}
                  </Grid>
                </TabPanel>
              </TabContext>
            </Box>
            {acceptRefuseModal.active && (
              <Modal
                sx={{ height: '20vh' }}
                open={true}
                closed={() => setAcceptRefuseModal({ active: false, btn: '' })}
              >
                <Grid>
                  <Typography
                    align="center"
                    component="h5"
                    variant="h5"
                    sx={{ mb: 2 }}
                  >
                    Sunteți sigur că doriți să{' '}
                    {acceptRefuseModal.btn === 'Refuză'
                      ? 'refuzați'
                      : 'acceptați'}{' '}
                    ?
                  </Typography>
                  <Box>
                    {acceptRefuseModal.student.e_repartizat === 1 ? (
                      <TextareaAutosize
                        margin="normal"
                        required
                        aria-label="empty textarea"
                        placeholder="Introduceți mesajul pe care doriți să-l transmiteți studentului..."
                        name=""
                        id=""
                        cols="60"
                        rows="10"
                        ref={motivRef}
                        minRows={10}
                        style={{
                          width: '100%',
                          resize: 'none',
                          padding: '0.7em',
                          fontFamily: 'sans',
                          fontSize: '1.2em',
                        }}
                        maxRows={10}
                      />
                    ) : (
                      ''
                    )}
                    <Button
                      variant="contained"
                      color={acceptRefuseModal.btn === 'Refuză'
                        ? 'error'
                        : 'success'}
                      sx={{ width: '45%', m: '2.5%' }}
                      onClick={() => {
                        if (acceptRefuseModal.cerere) {
                          refuzaCererea(
                            acceptRefuseModal.student,
                            motivRef.current.value
                          );
                        } else {
                          if (acceptRefuseModal.btn === 'Refuză') {
                            setPossibleStudents(prevState => {
                              const prevStuds = [...prevState];
                              prevStuds.splice(
                                prevStuds.indexOf(acceptRefuseModal.student),
                                1
                              );
                              return prevStuds;
                            });
                            sendRequest(acceptRefuseModal.student.id, false);
                            setAcceptRefuseModal({ active: false });
                          }
                          if (acceptRefuseModal.btn === 'acceptați') {
                            setAcceptedStudents(prevState => {
                              return [...prevState, acceptRefuseModal.student];
                            });
                            setPossibleStudents(prevState => {
                              const prevStuds = [...prevState];
                              prevStuds.splice(
                                prevStuds.indexOf(acceptRefuseModal.student),
                                1
                              );
                              return prevStuds;
                            });
                            sendRequest(acceptRefuseModal.student.id, true);
                          }
                        }
                        setAcceptRefuseModal({ active: false });
                      }}
                    >
                      {acceptRefuseModal.btn === 'Refuză'
                        ? 'Refuză'
                        : 'Acceptă'}
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ width: '45%', m: '2.5%' }}
                      onClick={() =>
                        setAcceptRefuseModal({ active: false, btn: '' })
                      }
                    >
                      NU
                    </Button>
                  </Box>
                </Grid>
              </Modal>
            )}
            {uploadModal.active && (
              <Modal
                sx={{ height: 500 }}
                open={true}
                closed={() => setUploadModal({ active: false, student: null })}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    component="h5"
                    variant="h5"
                    sx={{ mb: 2 }}
                  >
                    Sunteți sigur că doriți să încărcați cererea studentului{' '}
                    {uploadModal.student.nume +
                      ' ' +
                      uploadModal.student.prenume}
                    ? Aceasta trebuie să fie semnată!
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <TextField
                      margin='normal'
                      type='file'
                      accept='.pdf'
                      required
                      fullWidth
                      onChange={onFileChange}
                    />
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: '45%', m: 1, mt: 3 }}
                      onClick={() => {
                        if (!file) {
                          toast.error(
                            'Alege fișierul pe care vrei sa îl încarci'
                          );
                          return;
                        }
                        const fileReader = new FileReader();
                        let base64;
                        fileReader.onload = e => {
                          base64 = e.target.result;
                          fetch(SERVER + 'cerere', {
                            method: 'PATCH',
                            headers: {
                              Authorization: localStorage.getItem('token'),
                              'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                              fisier: base64,
                              idStudent: uploadModal.student.id,
                            }),
                          }) // refactor verificare cod ok
                            .then(res => {
                              return res.json();
                            })
                            .then(data => { });
                        };
                        fileReader.readAsDataURL(file);
                        setUploadModal({ active: false, student: null });

                        setToSign(prevState => {
                          const prevStuds = [...prevState];
                          prevStuds.splice(
                            prevStuds.indexOf(uploadModal.student),
                            1
                          );
                          return prevStuds;
                        });
                        toast.success('Cererea s-a încarcat cu succes!');
                      }}
                    >
                      Încarcă
                    </Button>
                    <Button
                      color="error"
                      variant='outlined'
                      sx={{ width: '45%', m: 1, mt: 3 }}
                      onClick={() =>
                        setUploadModal({ active: false, student: null })
                      }
                    >
                      Nu încă
                    </Button>
                  </Box>
                </Grid>
              </Modal>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ProfessorPage;
