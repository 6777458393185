import { GoogleLogin } from 'react-google-login';
import React from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function LoginForm({ onLogin }) {
  const [scroll] = React.useState('paper');
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onRefreshToken = res => {
    let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
    const refreshToken = async () => {
      const newAuthRes = await res.reloadAuthResponse();
      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
      localStorage.setItem('token', newAuthRes.id_token);
      setTimeout(refreshToken, refreshTiming);
    };
    setTimeout(refreshToken, refreshTiming);
  };

  const onSuccess = res => {
    localStorage.setItem('token', res.tokenId);
    const email = res.profileObj.email;
    const provider = email.split('@')[1];
    if (
      provider !== 'stud.ase.ro' &&
      provider !== 'csie.ase.ro' &&
      provider !== 'ie.ase.ro' &&
      provider !== 'ase.ro'
    )
      toast.error('Utilizati un e-mail institutional!');
    else {
      onRefreshToken(res);
      onLogin(provider);
    }
  };

  const onFailure = res => {
    localStorage.removeItem('token');
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item xs={false} sm={2} md={3} />
        <Grid item xs={12} sm={8} md={6} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <GoogleIcon />
            </Avatar>
            <Typography align="center" component="h4" variant="h4">
              Înscriere susținere licență
            </Typography>
            <Typography
              align="center"
              sx={{ mt: 2, mb: 10 }}
              component="h5"
              variant="h5"
            >
              Colaborezi rapid și ușor cu un profesor coordonator.
            </Typography>
            <Typography align="center" component="p" variant="p">
              Autentifică-te prin contul tău instituțional.
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                buttonText="Login"
                fullWidth
                onSuccess={onSuccess}
                onFailure={onFailure}
                cookiePolicy={'single_host_origin'}
                isSignedIn
                render={renderProps => (
                  <Button
                    sx={{ mt: 2 }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={renderProps.onClick}
                  >
                    Login
                  </Button>
                )}
              />
              <div>
                <Typography
                  sx={{ mt: 6 }}
                  align="center"
                  component="p"
                  variant="p"
                >
                  <b>
                    <i>
                      Prin autentificarea pe acest site, sunteți de <br></br>{' '}
                      acord cu termenii și condițiile de mai jos.
                    </i>
                  </b>
                </Typography>
                <Button
                  sx={{ mt: 0 }}
                  fullWidth
                  variant="text"
                  onClick={handleClickOpen}
                >
                  Termeni și condiții
                </Button>
                <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  scroll={scroll}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title"
                >
                  <DialogTitle color="black" id="responsive-dialog-title">
                    Termeni și condiții
                  </DialogTitle>
                  <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText>
                      <b>
                        {' '}
                        Vă rugăm să vă înregistrați doar dacă sunteți cadru
                        didactic în cadrul Departamentului de Informatică și
                        Cibernetică Economică al C.S.I.E. sau student în anul
                        III de studii (ori an suplimentar).
                      </b>
                      <br></br>
                      <br></br>
                      Prin autentificarea în cadrul aplicației sunteți de acord
                      ca aceasta să vă utilizeze datele personale necesare
                      procesului de colaborare pentru elaborarea lucrării de
                      licență, atât ca student, cât și ca profesor. Academia de
                      Studii Economice nu poate fi trasă la răspundere pentru
                      divulgarea accidentală a datelor personale înregistrate la
                      nivelul aplicației în urma unui atac informatic sau a unei
                      breșe de securitate.
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleClose(true)}
                      autoFocus
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <lottie-player
                src="https://assets6.lottiefiles.com/packages/lf20_kgotdjba.json"
                background="transparent"
                speed="1"
                style={{ width: '300px', height: '300px' }}
                loop
                autoplay
              ></lottie-player>
            </Box>
          </Box>
          <footer class="footer">
		    <Typography align="center" component="p" variant="p" sx={{mb:2}}>
              © Iancu Cristina, Dragomir Radu, Constantinescu Vlad
            </Typography>
            <Typography align="center" component="p" variant="p" sx={{mb:2}}>
              Departamentul de Informatică și Cibernetică Economică <br></br>
              (www.dice.ase.ro)
            </Typography>
          </footer>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
export default LoginForm;
